export const dashboardItems = [
  {
    title: 'Invoices',    
    description: 'Upload and approve invoices. They can be set to automatically be created in Xero and also update cost prices in the stock management system',
    route: '/',
    icon: 'mdi-file-document-outline',
    color: 'blue',
    children: [
      { title: 'Upload Invoices', component: 'UploadInvoices', route: '/upload-invoices', icon: 'mdi-file-upload', color: 'blue' },
      { title: 'Invoice Management', component: 'InvoiceManagement', route: '/invoice-management', icon: 'mdi-cash-register', color: 'blue' }
    ]
  },
  {
    title: 'Bills of Materials',
    description: 'Manage bills of materials for manufacturing products. This includes the ability to create a new bill of material or update existing ones',
    route: '/',
    icon: 'mdi-clipboard-list-outline',
    color: 'green',
    children: [
      { title: 'BOM Management', component: 'BOM', route: '/bom', icon: 'mdi-clipboard-list-outline', color: 'green' },
      { title: 'Manufacturing', component: 'Manufacturing', route: '/manufacturing', icon: 'mdi-factory', color: 'orange' }
    ]
  },
  {
    title: 'Purchase Orders',
    component: 'PurchaseOrders',
    description: 'Download and send purchase orders to suppliers',
    route: '/purchase-orders',
    icon: 'mdi-cart-outline',
    color: 'purple'
  },
  {
    title: 'Batch Codes',
    component: 'BatchCodes',
    description: 'Manage batch codes for stock control',
    route: '/batch-codes',
    icon: 'mdi-barcode',
    color: 'red'
  },
  {
    title: 'SKU Codes',
    component: 'GenerateSKU',
    description: 'Generate approved SKU codes for new products',
    route: '/sku-codes',
    icon: 'mdi-tag-outline',
    color: 'pink'
  },
  {
    title: 'Stock Take',
    component: 'StockTakeManagement',
    description: 'Manage stock takes for the warehouse',
    route: '/stock-take',
    icon: 'mdi-scale-balance',
    color: 'orange'
  },
  {
    title: 'Configuration',    
    description: 'System configuration and settings',
    route: '/',
    icon: 'mdi-cog',
    color: 'grey',
    children: [
      { title: 'General Settings', component: 'Config', route: '/config', icon: 'mdi-cog', color: 'grey' },
      { title: 'User Management', component: 'UserManagement', route: '/user-management', icon: 'mdi-account-cog', color: 'grey' }
    ]
  },
  // Add more items as needed
];

export const navigationItems = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    route: '/',
    color: 'primary'
  },
  ...dashboardItems,
  { title: 'Logout', icon: 'mdi-logout', route: null },
];

