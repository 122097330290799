<template>
  <v-app v-if="isAuthenticated">
    <v-overlay :model-value="loading" class="align-center justify-center">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-navigation-drawer v-model="drawer" app width="300">
      <v-list>
        <template v-for="item in items" :key="item.title">
          <v-list-group
            v-if="item.children"
            :value="item.title"
            :color="item.color"
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" :title="item.title">
                <template v-slot:prepend>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              :title="child.title"
              @click="navigate(child.route)"
            >
              <template v-slot:prepend>
                <v-icon :color="child.color">{{ child.icon }}</v-icon>
              </template>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="`${item.title}-else`"
            :title="item.title"
            @click="item.route ? navigate(item.route) : logout()"
          >
            <template v-slot:prepend>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </template>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ currentComponentName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <v-menu v-if="currentUser" offset-y>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" text>            
            <div class="text-left text-caption">
              <div>{{ currentUser.name }}</div>
              <div class="text-caption text-grey">{{ currentUser.email }}</div>
            </div>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted, nextTick, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex'; // Import useStore if you're using Vuex
import { useToast } from "vue-toastification"; // Import useToast for notifications
import { navigationItems } from '@/navigationConfig';

export default {
  name: 'Layout',
  setup() {
    const drawer = ref(true); // Set drawer to true initially
    const router = useRouter();
    const store = useStore(); // Initialize store if using Vuex
    const toast = useToast(); // Initialize toast
    const items = navigationItems;

    const navigate = (route) => {
      router.push(route);
    };

    const forceDrawerRender = () => {
      nextTick(() => {
        drawer.value = !drawer.value;
        drawer.value = !drawer.value;
      });
    };

    onMounted(async () => {
      console.log('Layout mounted, isAuthenticated:', isAuthenticated.value);
      if (isAuthenticated.value) {
        await store.dispatch('fetchUserData');
      }
      forceDrawerRender();
      window.addEventListener('resize', forceDrawerRender);
    });

    const logout = async () => {
      try {
        // If you're using Vuex for state management:
        await store.dispatch('logout');
        
        // If not using Vuex, you can directly remove the token:
        // localStorage.removeItem('token');
        
        toast.success('Logged out successfully');
        router.push('/login');
      } catch (error) {
        console.error('Logout failed:', error);
        toast.error('Logout failed: ' + error.message);
      }
    };

    const isAuthenticated = computed(() => store.getters.isLoggedIn);

    const route = useRoute();
    
    const currentComponentName = computed(() => {      
      return route.name || '';
    });

    // Add a watcher to log route changes
    watch(() => route.name, (newName) => {
      console.log('Route changed to:', newName);
    });

    const currentUser = computed(() => store.getters.currentUser);

    const loading = computed(() => store.state.loading);

    return {
      drawer,
      items,
      navigate,
      logout,
      isAuthenticated,
      currentComponentName,
      currentUser, // Add this to the returned object
      loading,
    };
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  width: 250px;
}
</style>
